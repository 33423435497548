var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content-vertical-nav',{attrs:{"nav-menu-items":_vm.navMenuItems},scopedSlots:_vm._u([{key:"basket",fn:function(){return [(_vm.$store.state.app.basketDrawer)?_c('product-basket'):_vm._e()]},proxy:true},{key:"navbar",fn:function(ref){
var isVerticalNavMenuActive = ref.isVerticalNavMenuActive;
var toggleVerticalNavMenuActive = ref.toggleVerticalNavMenuActive;
var scrollY = ref.scrollY;
return [(_vm.$vuetify.breakpoint.lgAndUp)?_c('div',[_c('h2',{staticClass:"mt-3 mr-9",staticStyle:{"color":"white !important"}},[_vm._v("Online")])]):_vm._e(),_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"d-flex align-center justify-end"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('div',{staticClass:"d-flex flex-grow-1 align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"app-logo ml-3",attrs:{"src":_vm.isDark ? _vm.appLogoDark : _vm.appLogo,"max-width":"50px","alt":"logo","contain":"","eager":""}})],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":""},on:{"click":toggleVerticalNavMenuActive}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenu)+" ")])],1)],1):_vm._e(),(_vm.userData.role == 'PLSYR' && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-sheet',{staticClass:"ml-md-3 overflow-hidden",staticStyle:{"width":"auto"}},[(_vm.userData.role === 'PLSYR')?_c('customer-list',{staticClass:"pl-0 pl-lg-6",attrs:{"style-theme":true}}):_vm._e()],1):_c('div',{staticClass:"d-flex align-center flex-lg-grow-1 justify-space-between"},[(_vm.userData.role !== 'SUPER' && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-sheet',{staticClass:"flex-grow-1 overflow-hidden"},[(_vm.userData.role === 'PLSYR')?_c('customer-list',{staticClass:"pl-0 pl-lg-6",attrs:{"style-theme":true}}):_vm._e()],1):_vm._e()],1),_c('app-bar-basket',{staticClass:"ml-9"}),_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"14","min-width":"230","content-class":"user-profile-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"size":"26","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCircle)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text mr-2"},[_vm._v(" "+_vm._s(_vm.userData.adsoyad)+" ")])],1),_c('v-icon',{attrs:{"size":"24","color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1)],1)]}}],null,true)},[_c('v-list',[(_vm.userData.role != 'SUBCUST')?_c('v-list-item',{on:{"click":function($event){return _vm.routerPush(_vm.userData.uid)}}},[_c('v-list-item-icon',{staticClass:"me-2 black--text"},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCircle)+" ")])],1),_c('v-list-item-content',{staticClass:"black--text"},[_c('v-list-item-title',[_vm._v("Kullanıcı Hesabım")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.logoutUser}},[_c('v-list-item-icon',{staticClass:"me-2 black--text"},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogout)+" ")])],1),_c('v-list-item-content',{staticClass:"black--text"},[_c('v-list-item-title',[_vm._v("Çıkış")])],1)],1)],1)],1)],1),(_vm.userData.role !== 'SUPER')?_c('div',{staticClass:"d-flex"},[_c('v-sheet',{staticClass:"d-flex align-center ml-3 rounded-pill",attrs:{"color":_vm.colorSelect()}})],1):_vm._e()])]}}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }