<template>
  <div>
    <div class="half-line"></div>

    <v-subheader v-if="canViewVerticalNavMenuHeader(item)">
      <span
        v-show="!menuIsVerticalNavMini || (menuIsVerticalNavMini && isMouseHovered)"
        class="title-wrapper mt-6"
        :class="{ 'no-style': menuIsVerticalNavMini && !isMouseHovered }"
      >
        <span>{{ t(item.subheader) }}</span>
        <hr class="solid" />
      </span>

      <v-icon v-show="menuIsVerticalNavMini && !isMouseHovered">
        {{ icons.mdiMinus }}
      </v-icon>
    </v-subheader>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { useUtils } from '@core/libs/i18n'
import { mdiMinus } from '@mdi/js'
import { inject } from '@vue/composition-api'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')
    const { t } = useUtils()
    const { canViewVerticalNavMenuHeader } = useAclUtils()

    return {
      menuIsVerticalNavMini,
      isMouseHovered,

      // i18n
      t,

      // ACL
      canViewVerticalNavMenuHeader,

      // Icons
      icons: {
        mdiMinus,
      },
    }
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

@include theme(v-subheader) using ($material) {
  white-space: normal;
  position: relative;

  .v-icon {
    color: map-deep-get($material, 'dividers');
  }

  .title-wrapper {
    &:not(.no-style) {
      width: 100%;
      // text-align: center;
      position: absolute;
      left: 0;
      border-bottom: 1px solid map-deep-get($material, 'dividers');
      line-height: 0.1em;
      // margin: 10px 0 20px;
    }
  }

  .title-wrapper span {
    background: map-deep-get($material, 'background');
    background-color: black;
    padding: 0 10px;
    font-size: 0.9rem;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }
}
.solid {
  border-top: 3px solid #ffffff !important;
  color: white !important;
  opacity: 1;
}
.half-line {
  width: 50%;
  border-bottom: 1px solid #000;
  margin: 0 auto;
}
</style>
