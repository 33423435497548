<template>
  <div class=" align-center ">
    <router-link to="/" class="d-flex align-center text-decoration-none">
      <v-slide-x-transition>
        <v-img
          v-show="!(menuIsVerticalNavMini && !isMouseHovered)"  
          max-width="259" 
          :src="isDark ? textLogoDark : textLogo"
        ></v-img>
      </v-slide-x-transition>
    </router-link>
    <!--  
    <v-slide-x-transition>
      <div
        v-show="!(menuIsVerticalNavMini && !isMouseHovered)"
        v-if="$vuetify.breakpoint.lgAndUp"
        class="d-flex align-center ms-1 mt-2"
        @click.stop="menuIsVerticalNavMini = !menuIsVerticalNavMini"
      >
        <v-icon v-show="!menuIsVerticalNavMini" size="20" class="cursor-pointer">
          {{ icons.mdiRecordCircleOutline }}
        </v-icon>
        <v-icon v-show="menuIsVerticalNavMini" size="20" class="cursor-pointer">
          {{ icons.mdiRadioboxBlank }}
        </v-icon>
      </div>
      <v-icon v-else size="20" class="d-inline-block" @click.stop="$emit('close-nav-menu')">
        {{ icons.mdiClose }}
      </v-icon>
    </v-slide-x-transition>
    -->
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiRadioboxBlank, mdiRecordCircleOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { inject } from '@vue/composition-api'

export default {
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')
    const { isDark } = useAppConfig()

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      isDark,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appLogoDark: themeConfig.app.logoDark,
      textLogo: themeConfig.app.textLogo,
      textLogoDark: themeConfig.app.textLogoDark,

      // Icons
      icons: {
        mdiClose,
        mdiRadioboxBlank,
        mdiRecordCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)

.app-close,
.app-menu-mini {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
