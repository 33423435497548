import { addDays, formatDate, postData, querySearch } from '@utils'

export default {
  namespaced: true,
  state: {
    basketItemCount: 0,
    basketItemChange: {},
    deliveryDate: formatDate(addDays(1)),
    deliveryMinDate: formatDate(addDays(1)),
    deliveryMaxDate: formatDate(addDays(8)),
    deliveryMaxDatePLSYR: formatDate(addDays(30)),
    basketList: [],
  },
  getters: {
    doneDelivery(state) {
      return state.deliveryDate
    },
    doneDeliveryMax(state) {
      return state.deliveryMaxDate
    },
    doneDeliveryMin(state) {
      return state.deliveryMinDate
    },
    doneBasket(state) {
      return state.basketItemChange
    },
  },
  mutations: {
    BASKET_ITEM_COUNT(state, value) {
      state.basketItemCount = value
    },
    BASKET_ITEM_CHANGE(state, value) {
      state.basketItemChange = value
    },
    DELIVERY_DATE(state, value) {
      state.deliveryDate = value
    },
    DELIVERY_DATE_MAX(state, value) {
      state.deliveryMaxDate = value
    },
    DELIVERY_DATE_MIN(state, value) {
      state.deliveryMinDate = value
    },
  },
  actions: {
    fetchBasketItemCount(state) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getBasketItemCount',
        })
          .then(response => {
            if (response.error === 1) {
              resolve(response)
            } else {
              state.commit('BASKET_ITEM_COUNT', response.itemCount)
              resolve(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchProductCategory() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getCategories',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchDeleteBasketItem(ctx, { id, plant }) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'deleteBasketItem',
          id: `${id}-${plant}`,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTrashBasket(ctx) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'emptyBasket',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchAddToBasket(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData(payload)
          .then(response => {
            resolve(response)
            ctx.dispatch('fetchBasketItemCount')
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPlants() {
      return new Promise((resolve, reject) => {
        postData({ method: 'getPlants' })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchAddressList(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCargoList(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchBankaList(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchBinList(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSaveBasketForSubcust(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'saveBasketForSubcust',
          buyer_note: payload.buyer_note,
          address: payload.address,

          // customAddress: payload.customAddress,
          cargo: payload.cargo,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchActiveBasketList(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getAllBasket',
        })
          .then(response => {
            let result = { error: true }

            // let basket01 = []
            // let basket02 = []

            // if (response.detail['01'].basketInfo?.Line?.length > 0) {
            //   basket01 = response.detail['01'].basketInfo.Line
            //   basket01.forEach(item => (item.plant = 'Tekirdağ'))
            //   basket01.forEach(item => (item.plantID = '01'))
            // }

            // if (response.detail['02'].basketInfo?.Line?.length > 0) {
            //   basket02 = response.detail['02'].basketInfo.Line
            //   basket02.forEach(item => (item.plant = 'Konya'))
            //   basket02.forEach(item => (item.plantID = '02'))
            // }

            const { summary } = response
            const allBasket = response.detail?.DEF?.basketInfo?.Line

            ctx.dispatch('fetchBasketItemCount')

            ctx.commit('BASKET_ITEM_CHANGE', allBasket)
            const toplamlar = response.detail?.DEF?.basketInfo

            result = {
              error: false,
              toplamlar,
              detail: allBasket,
              summary,
            }

            resolve(result)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchGetOrderStep1Data(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getOrderStep1Data',
          hasPromotion: payload.hasPromotion,
          hasMalFazlasi: payload.hasMalFazlasi,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchGetOrderStep2Data() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getOrderStep2Data',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchGetOrderStep3Data() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getOrderStep3Data',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchGetOrderStep4Data() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getOrderStep4Data',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMakeOrderStep1(ctx, payload) {
      return new Promise((resolve, reject) => {
        debugger
        postData({
          method: 'makeOrderStep1',
          deliveryAddr: payload.deliveryAddr,
          deliveryDate: payload.deliveryDate,
          invoiceAddr: payload.invoiceAddr,
          cargo: payload.cargo,
          customAddress: payload.customAddress,
          customCity: payload.customCity,
          customDistrict: payload.customDistrict,
          customTitle: payload.customTitle,
          buyer_note: payload.buyer_note,
          iscustomAddress: payload.iscustomAddress,
        })
          .then(response => {
            debugger
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchMakeOrderStep2(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMakeOrderStep3(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData({
          method: 'makeOrderStep3',
          sozlesme0: 1,
          sozlesme1: 1,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchGetOrderTracking(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        postData(querySearch(queryParams, 'getSales'))
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchAddressCities(ctx, payload) {
      return new Promise((resolve, reject) => {
        postData(payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
