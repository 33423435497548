<template>
  <div class="vertical-shopping-cart-container pt-10 pt-md-0">
    <div
      v-if="!$vuetify.breakpoint.mdAndUp"
      class="text-right pa-1 elevation-10"
      style="position: fixed; top: 0; left: 0; width: 100%; z-index: 10"
    >
      <v-btn x-small block color="error" @click="closeBasket()">
        <span class="text-caption text-capitalize">Sepeti Kapat</span>
        <v-icon right>
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-snackbars :objects.sync="snackbar" width="500" top left transition="slide-y-transition">
      <template v-slot:action="{ close }">
        <v-btn icon small @click="close()">
          <v-icon small>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbars>

    <v-dialog v-model="dialog" eager width="500" persistent>
      <v-btn color="error" block @click="paymentCancel">
        <v-icon left>
          {{ icons.mdiClose }}
        </v-icon>
        İşlemi İptal Et
      </v-btn>
      <div id="credit"></div>
    </v-dialog>

    <v-dialog v-model="mesafeliStatus" scrollable max-width="600px">
      <v-card>
        <v-card-title>Mesafeli Satış Sözleşmesi</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <div v-html="mesafeliSatisSozlesmesi"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="mesafeliStatus = false"> Kapat </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="onBilgilendirmeStatus" scrollable max-width="600px">
      <v-card>
        <v-card-title>Ön Bilgilendirme Sözleşmesi</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <div v-html="onBilgilendirmeSozlesmesi"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="onBilgilendirmeStatus = false"> Kapat </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <perfect-scrollbar
      id="container"
      ref="scrollbar"
      class="ps-nav-menu-items"
      :options="perfectScrollbarOptions"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <v-stepper
        v-if="$store.state['app-order'].basketItemCount > 0"
        v-model="basketStep"
        class="basket-stepper"
        vertical
        elevation="0"
      >
        <v-stepper-step
          :complete="basketStep > 1"
          :editable="basketStep > 1 && !loading"
          step="1"
          :class="$vuetify.breakpoint.mdAndUp ? 'px-2' : 'pa-0'"
        >
          <v-list class="pa-0">
            <v-list-item>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
                :color="basketStep == 1 ? 'secondary' : 'primary'"
              >
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                  {{ icons.mdiBasket }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Sepet </v-list-item-title>

                <div v-if="basketStep != 1" class="d-block d-md-flex justify-space-between">
                  <div class="flex-grow-1">
                    <v-list-item-subtitle
                      class="text-caption font-weight-thin mb-1 mb-md-2"
                      style="text-shadow: none !important"
                    >
                      <span style="color: black" class="font-weight-medium">Ürün Sayısı : </span>
                      <span class="text-decoration-underline" style="color: black">{{ urunSayisi }} Adet</span>
                    </v-list-item-subtitle>
                  </div>
                  <div class="flex-grow-1"></div>
                </div>
              </v-list-item-content>

              <v-list-item-action v-if="basketStep == 1 && $vuetify.breakpoint.mdAndUp">
                <v-btn icon @click="closeBasket()">
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep != 1 && $vuetify.breakpoint.mdAndUp">
                <v-chip v-if="basketStep != 1" large color="">
                  <span class="text-body-2 px-3"> Sepet Toplam Tutarı</span>
                  <v-chip color="info">
                    {{ toplamFiyat | currency }}
                  </v-chip>
                </v-chip>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep != 1 && !$vuetify.breakpoint.mdAndUp">
                <v-chip label class="text-caption">
                  {{ toplamFiyat | currency }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list class="pa-0">
            <v-alert v-if="isSubcust.isSubcust == 1" class="mt-4" type="info" variant="tonal">
              Şu anda <strong>{{ isSubcust.extra.name }}</strong> adına işlem yapıyorsunuz
            </v-alert>
          </v-list>
        </v-stepper-step>

        <v-stepper-content
          step="1"
          :style="$vuetify.breakpoint.mdAndUp ? 'padding: 16px 45px 16px 8px;' : 'padding: 16px 45px 16px 8px;'"
        >
          <v-card v-if="!sepetLoading" color="grey lighten-1" class="mb-6" rounded="lg">
            <v-card-text :class="!$vuetify.breakpoint.mdAndUp ? 'pa-0' : ''">
              <v-list class="pa-0" color="grey" :dense="!$vuetify.breakpoint.mdAndUp">
                <div v-for="(line, index) in shoppingCartItems.detail" :key="`LIST-${index}`">
                  <v-hover v-slot="{ hover }">
                    <v-list-item
                      :three-line="!$vuetify.breakpoint.mdAndUp"
                      :two-line="$vuetify.breakpoint.mdAndUp"
                      :class="hover ? 'grey' : null"
                      class="px-2"
                    >
                      <v-list-item-action style="color: black">
                        {{ line.prd_code }}
                      </v-list-item-action>
                      <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'ml-3' : ' align-self-center'">
                        <v-list-item-title style="color: black; white-space: normal !important">
                          <div>
                            <span> {{ line.baslik }}</span>
                          </div>
                        </v-list-item-title>
                        <v-spacer></v-spacer>
                        <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" style="color: black" class="mt-1">
                          <span class="black--text"> {{ line.birimFiyat | currency }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" style="color: black" class="mt-1">
                          <span class="black--text"> {{ (line.birimFiyat * line.qty) | currency }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                        <span class="black--text mr-2"> {{ line.birimFiyat | currency }}</span>
                      </v-list-item-action>

                      <v-list-item-action>
                        <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 160 : 100" color="transparent">
                          <CounterTo
                            :uid="line.uid"
                            :plant-id="line.selected_plant"
                            :miktar="line.qty"
                            :birim="line.birim"
                            :paym-cond="line.paymCond"
                            :birim-ritim="line.birimRitmi"
                            bg-color="counter"
                            button-color="black"
                            :light="true"
                            @refreshBasket="refreshBasket()"
                          />
                        </v-sheet>
                      </v-list-item-action>

                      <v-list-item-action v-if="$vuetify.breakpoint.smAndUp" style="min-width: 90px">
                        <span class="black--text"> {{ (line.birimFiyat * line.qty) | currency }}</span>
                      </v-list-item-action>
                      <v-btn icon @click="deleteBasketItem(line.baslik, line.uid, line.selected_plant)">
                        <v-icon>
                          {{ icons.mdiTrashCan }}
                        </v-icon>
                      </v-btn>
                    </v-list-item>
                  </v-hover>
                  <v-divider class="hre"></v-divider>
                </div>

                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right" style="color: black"> Ara Toplam </v-list-item-title>
                    <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                      {{ toplamBrutFiyat | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="hre"></v-divider>

                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right" style="color: black"> İndirim </v-list-item-title>
                    <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                      -{{ toplamIndirimTutari | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="hre"></v-divider>

                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right" style="color: black"> KDV </v-list-item-title>
                    <v-list-item-subtitle class="text-right" style="color: rgb(63, 63, 63)">
                      {{ satirToplamKDV | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="hre"></v-divider>

                <v-list-item>
                  <v-list-item-content class="oneLine">
                    <v-list-item-title class="text-right" style="color: black"> Toplam Tutar </v-list-item-title>
                    <v-list-item-subtitle
                      class="text-right font-weight-bold success--text"
                      style="color: rgb(63, 63, 63)"
                    >
                      {{ toplamFiyat | currency }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-alert
                  v-if="userData.role != 'SUBCUST'"
                  tile
                  type="warning"
                  prominent
                  dense
                  text
                  outlined
                  class="my-alert mt-2"
                >
                  Siparişinize ait faturanıza GKKP (Geri Kazanım Katkı Payı) eklenecektir.
                </v-alert>

                <span v-if="userData.role === 'PLSYR'">
                  <v-divider></v-divider>
                </span>
              </v-list>
            </v-card-text>
            <v-card-actions class="px-2 px-md-4 pt-6 pt-md-2">
              <v-btn
                :plain="!$vuetify.breakpoint.mdAndUp"
                :small="!$vuetify.breakpoint.mdAndUp"
                text
                tile
                class="text-capitalize"
                style="color: rgb(63, 63, 63)"
                @click="trashBasket()"
              >
                <v-icon left style="color: rgb(244, 82, 82)">
                  {{ icons.mdiTrashCan }}
                </v-icon>
                Sepeti Temizle
              </v-btn>
              <v-spacer></v-spacer>
              <v-alert v-if="errorShow" type="error" class="ma-0" text outlined dense>
                <span class="text-caption">Kayıtlı adresiniz olmadığı için devam edemiyoruz</span>
              </v-alert>
              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                text
                class="text-capitalize"
                plain
                style="color: rgb(63, 63, 63)"
                @click="closeBasket"
              >
                Vazgeç
              </v-btn>
              <v-btn
                color="tertiary"
                class="text-capitalize white--text"
                x-large
                tile
                :loading="loading"
                @click="basketStep2()"
              >
                Devam
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-sheet v-else color="grey" class="rounded-lg d-flex justify-center pa-6">
            <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
          </v-sheet>
        </v-stepper-content>

        <v-stepper-step
          :complete="basketStep > 2"
          :editable="basketStep > 2 && !loading"
          :class="$vuetify.breakpoint.mdAndUp ? 'pa-0' : 'pa-0'"
          step="2"
        >
          <v-list class="pa-0">
            <v-list-item>
              <!--   <v-list-item-avatar
                :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
                :color="basketStep == 2 ? 'secondary' : 'primary'"
              >
                <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                  {{ icons.mdiTruckFast }}
                </v-icon>
              </v-list-item-avatar>

               <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Teslimat </v-list-item-title>-->

              <v-list-item-content>
                <div v-if="basketStep == 3" class="d-block d-md-flex justify-space-between">
                  <div class="flex-grow-1">
                    <v-list-item-subtitle class="text-caption font-weight-thin mb-1 mb-md-0" color="black">
                      <span style="color: black" class="font-weight-medium">Teslimat Adresi : </span>
                      <span class="text-decoration-underline" style="color: black">{{ address_title }}</span>
                    </v-list-item-subtitle>
                  </div>
                  <div class="flex-grow-1">
                    <v-list-item-subtitle class="text-caption font-weight-thin" style="text-shadow: none !important">
                      <span style="color: black" class="font-weight-medium">Teslimat Tarihi : </span>
                      <span class="text-decoration-underline" style="color: black">{{ deliveryDate }}</span>
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item-content>
              <v-list-item-action v-if="basketStep > 2 && $vuetify.breakpoint.mdAndUp">
                <v-chip large color="">
                  <span class="text-body-2 px-3"> Teslimat Tutarı</span>
                  <v-chip color="info">
                    {{ cargoPrice | currency }}
                  </v-chip>
                </v-chip>
              </v-list-item-action>
              <v-list-item-action v-else-if="basketStep > 2 && !$vuetify.breakpoint.mdAndUp">
                <v-chip label class="text-caption">
                  {{ cargoPrice | currency }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-stepper-step>

        <v-stepper-content step="2" :style="$vuetify.breakpoint.mdAndUp ? null : 'padding: 16px 45px 16px 8px;'">
          <v-card color="grey lighten-1" class="mb-12">
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="mt-5" x-large left>
                    {{ icons.mdiMapMarker }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-autocomplete
                    v-model="addressSelect1"
                    label="Adres Seçiniz"
                    :items="adressSehir"
                    :hint="`${adressSehir.text}, ${adressSehir.id}`"
                    item-title="text"
                    item-value="value"
                    :disabled="disableItem"
                    class="rounded-0 mt-3 custom-select"
                    :menu-props="{ bottom: true, offsetY: true }"
                    filled
                    clearable
                    hide-details
                    return-object
                    search
                    persistent-hint
                  >
                  </v-autocomplete>
                </v-list-item-content>
              </v-list-item>
              <v-col>
                <v-col v-if="userData.role != 'SUBCUST' && addressSelect1.value == 0" class="ml-14">
                  <v-textarea
                    v-model="farkliMusteriUnvani"
                    label="Alıcının Unvanını Giriniz"
                    rows="1"
                    auto-grow
                    class="rounded-0 mt-3 custom-select pa-0"
                    style="width: 850px"
                    :menu-props="{ bottom: true, offsetY: true }"
                    hide-details
                    clearable
                    :maxlength="80"
                  ></v-textarea>
                </v-col>

                <v-col v-if="userData.role != 'SUBCUST' && addressSelect1.value == 0" class="ml-14">
                  <v-textarea
                    v-model="farkliAdress"
                    clearable
                    label="Adres Giriniz"
                    rows="1"
                    auto-grow
                    class="rounded-0 mt-3 custom-select pa-0"
                    style="width: 850px"
                    :menu-props="{ bottom: true, offsetY: true }"
                    hide-details
                  ></v-textarea>
                </v-col>
                <v-col v-if="userData.role != 'SUBCUST' && addressSelect1.value == 0" class="ml-14">
                  <v-autocomplete
                    v-model="farkliSehirSelect1"
                    label="Şehir Seçiniz"
                    :items="farkliSehir"
                    item-title="text"
                    item-value="value"
                    :disabled="disableItem"
                    :menu-props="{ bottom: true, offsetY: true }"
                    clearable
                    hide-details
                    return-object
                    search
                    persistent-hint
                    class="rounded-0 mt-3 custom-select pa-0"
                    style="width: 850px"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col v-if="userData.role != 'SUBCUST' && addressSelect1.value == 0" class="ml-14">
                  <v-textarea
                    v-model="farkliIlce"
                    label="İlçe Giriniz"
                    rows="1"
                    auto-grow
                    class="rounded-0 mt-3 custom-select pa-0"
                    style="width: 850px"
                    :menu-props="{ bottom: true, offsetY: true }"
                    hide-details
                    clearable
                  ></v-textarea>
                </v-col>
              </v-col>
            </v-list>

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="mt-4" x-large left>
                    {{ icons.mdiTruckFast }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-select
                    v-model="teslimatTipsSelect1"
                    label="Teslimat Tipleri"
                    :items="teslimatTips"
                    item-value="id"
                    item-text="text"
                    class="rounded-0 custom-select"
                    :menu-props="{ bottom: true, offsetY: true }"
                    filled
                    clearable
                    hide-details
                  ></v-select>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider class="my-3"></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large left>
                    {{ icons.mdiCalendar }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Teslimat Tarihi</v-list-item-title>
                  <v-list-item-subtitle> {{ deliveryDate | moment('Do MMMM YYYY dddd') }} Günü</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-menu v-model="menuDeliveryDate" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="text-capitalize"
                        depressed
                        tile
                        :fab="!$vuetify.breakpoint.mdAndUp"
                        color="secondary"
                        v-on="on"
                      >
                        <v-icon :left="$vuetify.breakpoint.mdAndUp">
                          {{ icons.mdiRefresh }}
                        </v-icon>
                        <span v-if="$vuetify.breakpoint.mdAndUp">Değiştir</span>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="deliveryDate"
                      scrollable
                      no-title
                      :min="minDate"
                      :max="maxDate"
                      :show-current="false"
                      :first-day-of-week="1"
                      @input="dateChange()"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>

              <v-divider class="my-3"></v-divider>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-alert
                      v-if="userData.role != 'SUBCUST'"
                      tile
                      type="warning"
                      prominent
                      dense
                      text
                      outlined
                      class="my-alert"
                    >
                      Siparişinize ait faturanız C/H mahsuben <strong>{{ xx }} Gün</strong> vadeli olarak
                      düzenlenecektir.
                      <br />
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-list>

            <v-divider class="my-3"></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large left>
                    {{ icons.mdiText }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Sipariş Notu</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-card-text>
              <v-textarea
                v-model="buyerNote"
                auto-grow
                placeholder="Bir not girmek istiyorsanız buraya yazın"
                rows="1"
                row-height="15"
              />
            </v-card-text>
          </v-card>

          <div class="mb-6 d-flex align-center">
            <v-btn
              :plain="!$vuetify.breakpoint.mdAndUp"
              :small="!$vuetify.breakpoint.mdAndUp"
              text
              tile
              outlined
              class="text-capitalize"
              style="color: rgb(63, 63, 63)"
              @click="backBasket(1)"
            >
              <v-icon left style="color: rgb(63, 63, 63)">
                {{ icons.mdiChevronUp }}
              </v-icon>
              Sepet
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="$vuetify.breakpoint.mdAndUp" text class="text-capitalize" plain @click="closeBasket">
              Vazgeç
            </v-btn>

            <v-btn
              color="tertiary"
              class="text-capitalize white--text"
              x-large
              tile
              :loading="loading"
              @click="basketStep3()"
            >
              Siparişi Tamamla
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
      <v-card v-else flat tile height="100%">
        <v-list class="py-6 px-2">
          <v-list-item>
            <v-list-item-avatar
              :size="$vuetify.breakpoint.mdAndUp ? 64 : 48"
              :color="basketStep == 1 ? 'primary' : 'grey'"
            >
              <v-icon :size="$vuetify.breakpoint.mdAndUp ? 32 : 24" class="white--text">
                {{ icons.mdiBasketOff }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-h6 text-md-h5 pb-1 font-weight-medium"> Sepet </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="basketStep == 1 && $vuetify.breakpoint.mdAndUp">
              <v-btn icon @click="closeBasket()">
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div class="d-flex align-center justify-center" style="height: calc(100% - 300px)">
          <div class="text-center">
            <v-icon color="primary" size="100">
              {{ icons.mdiBasketOff }}
            </v-icon>
            <div class="text-body-2 pt-1 pb-6">Sepetiniz Boş Lütfen Ürün Ekleyin</div>
            <v-btn depressed outlined small @click="closeBasket()"> Kapat </v-btn>
          </div>
        </div>
      </v-card>
    </perfect-scrollbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { getVuetify, useRouter } from '@core/utils'

import {
  mdiBank,
  mdiBasket,
  mdiBasketOff,
  mdiCalendar,
  mdiChevronDown,
  mdiChevronUp,
  mdiCircle,
  mdiClose,
  mdiCloseCircleOutline,
  mdiContactlessPayment,
  mdiCreditCard,
  mdiCreditCardPlus,
  mdiMapMarker,
  mdiPlus,
  mdiRefresh,
  mdiText,
  mdiTrashCan,
  mdiTruckFast,
} from '@mdi/js'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'

// 3rd Party
import CardForm from '@/components/CardForm.vue'
import CounterTo from '@/components/CounterTo.vue'
import store from '@/store'
import orderStoreModule from '@/views/order/orderStoreModule'
import { required } from '@core/utils/validation'
import { postData } from '@utils'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    CounterTo,
    CardForm,

    // 3rd Party
    PerfectScrollbar,
    VSnackbars,
  },
  setup() {
    const birinciKart = ref(null)
    const ikinciKart = ref(0)
    const userData = JSON.parse(localStorage.getItem('userData'))
    const buttonText = ref('Alışverişi Tamamla')
    const kredikartino = ref('')
    const kredikartiadsoyad = ref('')
    const kredikartiinstallment = ref([])
    const kredikartiay = ref('')
    const kredikartiyil = ref('')
    const kredikarticvv = ref('')
    const { router } = useRouter()
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    const shoppingCartItems = ref([])
    const addressItems = ref([])
    const disableItem = ref(false)
    const adressSehir = ref([])
    const teslimatTips = ref([])
    const mesafeliStatus = ref(false)
    const farkliAdress = ref('')
    const farkliSehir = ref('')
    const farkliIlce = ref('')
    const farkliMusteriUnvani = ref('')
    const isfarkliAddress = ref(0)
    const onBilgilendirmeStatus = ref(false)
    const errorShow = ref(false)
    const bankalar = ref([])
    const cargoPrice = ref(0)
    const toplamOdenecekTutar = ref(0)
    const toplamOdenecekTutarHavale = ref(0)
    const formValidate = ref([])
    const bankItem = ref(0)
    const buyerNote = ref(null)
    const valid = ref(true)
    const sepetLoading = ref(true)
    const cardNumberValidate = ref(false)
    const cardNameValidate = ref(false)
    const cardMountValidate = ref(false)
    const cardYearValidate = ref(false)
    const cardCvvValidate = ref(false)
    const kkAdSoyad = ref(null)
    const kkAy = ref(null)
    const kkYil = ref(null)
    const kkCvv = ref(null)
    const kkNo = ref(null)
    const selectedInstallment = ref(null)
    const bankaKodu = ref(null)
    const cardFamily = ref(null)
    const cardBankName = ref(null)
    const number = ref(2)
    const musteri = ref(1)
    const fabrika = ref(1)
    const tarih = ref(1)
    const adresSubcust = ref('')

    const sehirler = ref({})
    const shallShadowBottom = ref(false)
    const isMulti = ref(false)
    const basketStep = ref(1)
    const minDate = ref(null)
    const maxDate = ref(null)
    const tab = ref(0)
    const deliveryDate = ref(null)
    const loading = ref(false)
    const dialog = ref(false)
    const menuDeliveryDate = ref(false)
    const checkbox1 = ref(userData.role === 'PLSYR')
    const checkbox2 = ref(userData.role === 'PLSYR')
    const taksitlendirme = ref({})
    const taksitSecim = ref(null)
    const taksitTutar = ref(0)
    const taksitSayisi = ref(0)
    const snackbar = ref([])
    const form = ref(null)
    const whileStop = ref(false)
    const namaSevkDurum = ref(false)
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]
    const hasPromotion = ref(true)
    const hasMalFazlasi = ref(true)
    const addressSelect1 = ref({
      text: '',
      value: '',
    })
    const teslimatTipsSelect1 = ref({
      text: '',
      id: '',
    })
    const farkliSehirSelect1 = ref({
      text: '',
      id: '',
    })
    const scrollbar = ref()
    onMounted(() => {
      const $vuetify = getVuetify()

      // if ($vuetify.breakpoint.smAndDown) scrollbar.value.destroy()
    })

    onMounted(() => {
      deliveryDate.value = store.state['app-order'].deliveryDate
      minDate.value = store.state['app-order'].deliveryMinDate
      maxDate.value =
        userData.role === 'PLSYR'
          ? store.state['app-order'].deliveryMaxDatePLSYR
          : store.state['app-order'].deliveryMaxDate
    })
    const dateChange = () => {
      store.commit('app-order/DELIVERY_DATE', deliveryDate.value)
      console.log(addressSelect)
    }

    const addressSelect = ref([])
    const isSubcust = ref([])
    const teslimatTipsSelect = ref([])
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }
    const counts = computed(() => Array.from({ length: 100 }, (_, i) => i + 1))

    onMounted(() => {
      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          debugger
          shoppingCartItems.value = response
          isSubcust.value = response.toplamlar
          console.log(response.toplamlar.extra)
          dynamic.value = response.toplamlar.extra.address
          addressSelect1.value.value = dynamic.value
          farkliAdress.value = response.toplamlar.extra.customAddress
          farkliSehir.value = response.toplamlar.extra.customCity
          farkliIlce.value = response.toplamlar.extra.customDistrict
          farkliMusteriUnvani.value = response.toplamlar.extra.customTitle
          isfarkliAddress.value = response.toplamlar.extra.isCustomAddress
          console.log(dynamic.value)
          teslimatTipsSelect1.value = response.toplamlar.extra.cargo

          sepetLoading.value = false
        } else {
          // console.table('hatalı =>', response)
        }
      })
    })

    const refreshBasket = () => {
      store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          shoppingCartItems.value = response
        } else {
          //   console.table('hatalı =>', response)
        }
      })
    }

    const isRamazanEvent = (uid, qty, paymCond, plant, isRamazan) => {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid,
          qty,
          paymCond,
          plant,
          isRamazan,
        })
        .then(response => {
          if (response.error) {
          } else {
          }
        })
    }

    watch(
      () => store.getters['app-order/doneDelivery'],
      () => {
        refreshBasket()
      },
    )

    watch(
      () => addressSelect1.value,
      () => {
        console.log('degisti')
      },
    )

    watch(
      () => teslimatTipsSelect1.value,
      () => {
        console.log(teslimatTipsSelect1.value)
      },
    )

    const closeBasket = () => {
      store.commit('app/TOGGLE_BASKET_DRAWER', false)
    }

    const deleteBasketItem = (baslik, id, plant) => {
      store.commit('app/TOGGLE_BASKET_DRAWER_TEMPORARY', false)
      Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchDeleteBasketItem', { id, plant }).then(response => {
            if (response.error === 0) {
              snackbar.value.push({
                message: `${baslik} Ürünü Silindi`,
                color: 'error',
                timeout: 3000,
              })
              shoppingCartItems.value = []
              store.dispatch('app-order/fetchActiveBasketList').then(response => {
                if (!response.error) {
                  shoppingCartItems.value = response
                  if (typeof response.detail === 'undefined') {
                    store.commit('app/TOGGLE_BASKET_DRAWER', false)
                  }

                  //     store.commit('app/TOGGLE_BASKET_DRAWER_TEMPORARY', true)
                } else {
                  store.commit('app/TOGGLE_BASKET_DRAWER', false)
                }
              })
            } else {
              Vue.swal({
                title: 'Hata',
                html: response.msg,
                icon: 'error',
                background: '#FF4C51',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            }
          })
        }
      })
    }

    const trashBasket = () => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Sepeti Temizlemek İstiyormusunuz',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchTrashBasket').then(() => {
            store.dispatch('app-order/fetchActiveBasketList').then(response => {
              store.commit('app/TOGGLE_BASKET_DRAWER', false)
              Vue.swal({
                title: 'Sepet Temizlendi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            })
          })
        }
      })
    }

    const backBasket = val => {
      const container = document.querySelector('#container')
      container.scrollTop = 0
      basketStep.value = val
    }
    const responseStep2 = ref({})
    const onBilgilendirmeSozlesmesi = ref(null)
    const mesafeliSatisSozlesmesi = ref(null)
    const dynamic = ref('')
    const xx = ref('')

    const basketStep2 = () => {
      loading.value = true
      const payload = {
        hasPromotion: hasPromotion.value,
        hasMalFazlasi: hasMalFazlasi.value,
      }

      store
        .dispatch('app-order/fetchAddressList', {
          method: 'getCargoList',
          uid: '1039',
        })
        .then(response => {
          if (response.error === 0) {
            for (const [key, value] of Object.entries(response.detail)) {
              teslimatTips.value.push({
                text: value.kargo_adi,
                id: value.kid,
              })
            }

            // errorShow.value = false

            //  console.table('başarılı =>', response)
          }
        })

      store
        .dispatch('app-order/fetchAddressList', {
          method: 'getAddressListByUid',
          uid: '1039',
        })
        .then(response => {
          if (response.error === 0) {
            for (const [key, value] of Object.entries(response.response)) {
              adressSehir.value.push({
                text: `${value.name} - ${value.com_company_name} - ${value.address} - ${value.state} - ${value.city_txt}`,
                value: value.id,
              })
            }
            console.log(adressSehir.value)

            if (userData.role != 'SUBCUST') {
              adressSehir.value.unshift({
                text: 'Farklı bir adres girmek istiyorum',
                value: 0,
              })
            }

            // errorShow.value = false

            //  console.table('başarılı =>', response)
          }
        })

      store
        .dispatch('app-order/fetchAddressCities', {
          method: 'getCities',
        })
        .then(response => {
          if (response.error === 0) {
            debugger

            const cities = response.detail // Accessing suggestions from 'detail'
            farkliSehir.value = Object.keys(cities).map(key => ({
              value: key,
              text: cities[key],
            }))

            /*    for (const [key, value] of Object.entries(response.detail)) {
              farkliSehir.value.push({
                text: value,
                value: key,
              })
            } */
            console.log(farkliSehir.value)
          }
        })

      store
        .dispatch('app-order/fetchGetOrderStep1Data', payload)
        .then(response => {
          if (response.error === 0) {
            const container = document.querySelector('#container')
            container.scrollTop = 0
            basketStep.value = 2
            xx.value = response.detail.k_paymcond

            console.log(dynamic.value)
          } else {
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',

              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })

        .finally(() => {
          loading.value = false
        })
    }

    const tabHavaleDisabled = ref(false)
    const tabCHDisabled = ref(false)
    const tabCHnone = ref(false)

    if (userData.role === 'PLSYR') {
      tabCHnone.value = true
    }
    const basketStep3 = () => {
      debugger
      if (
        addressSelect1.value.value === 0 &&
        (farkliAdress.value === null || farkliAdress.value === '' || farkliAdress.value === undefined)
      ) {
        Vue.swal({
          title: 'Hata',
          html: 'Lütfen Adres Alanını Doldurunuz!',
          icon: 'error',
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: false,
        })
        loading.value = false

        return
      }
      console.log('farkliAdres', farkliAdress.value)
      console.log(0)
      loading.value = true
      console.log(1)
      if (userData.role !== 'SUBCUST') {
        if (addressSelect1.value.value === 0) {
          debugger
          addressSelect1.value.value = adressSehir.value[1].value
          isfarkliAddress.value = 1
        }
      }

      const payload = {
        buyer_note: buyerNote.value,
        customAddress: farkliAdress.value,
        customCity: farkliSehirSelect1.value.value,
        customDistrict: farkliIlce.value,
        customTitle: farkliMusteriUnvani.value,
        deliveryAddr: addressSelect1.value.value,
        invoiceAddr: addressSelect1.value.value,
        iscustomAddress: isfarkliAddress.value,

        // namaSevkAddressItems.value?.[addressSelect.value]?.id || adressSehir.value?.[addressSelect.value]?.id,
        cargo: teslimatTipsSelect1.value,
        deliveryDate: deliveryDate.value,
      }
      debugger
      console.log(2)
      const payload1 = {
        address: addressSelect1.value.value,
        buyer_note: buyerNote.value,

        // customAddress: adresSubcust.value,
        cargo: teslimatTipsSelect1.value,
      }
      debugger
      if (userData.role === 'SUBCUST') {
        store.dispatch('app-order/fetchSaveBasketForSubcust', payload1).then(result => {
          if (result.error === 0) {
            console.log('başarılı')
            store.dispatch('app-order/fetchTrashBasket').then(() => {
              store.dispatch('app-order/fetchActiveBasketList').then(response => {
                store.commit('app/TOGGLE_BASKET_DRAWER', false)
                Vue.swal({
                  title: 'Sipariş Tamamlandı',
                  text: 'İşlem başarılı',
                  icon: 'success',
                  timer: 2000,
                  background: '#56CA00',
                  timerProgressBar: true,
                  showConfirmButton: false,
                })
              })
            })
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              html: result.msg,
              icon: 'error',

              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
      } else {
        store.dispatch('app-order/fetchMakeOrderStep1', payload).then(result => {
          if (result.error === 0) {
            store.dispatch('app-order/fetchGetOrderStep2Data').then(response => {
              responseStep2.value = response
              const payloadPay = {
                method: 'makeOrderStep2',
                paymentType: 6,
                banka: bankalar.value[bankItem.value]?.id,
              }

              store.dispatch('app-order/fetchMakeOrderStep2', payloadPay).then(response => {
                if (response.error === 1) {
                  Vue.swal({
                    title: 'Hata',
                    html: response.msg,
                    icon: 'error',
                    background: '#FF4C51',
                    timerProgressBar: true,
                    showConfirmButton: false,
                  })
                  loading.value = false
                } else {
                  store.dispatch('app-order/fetchGetOrderStep3Data').then(response => {
                    // mesafeliSatisSozlesmesi.value = response.mesafeliSatisSozlesmesi
                    // onBilgilendirmeSozlesmesi.value = response.onBilgilendirmeSozlesmesi
                    const container = document.querySelector('#container')

                    container.scrollTop = 0
                    cargoPrice.value = responseStep2.value.detail?.ordering?.totalCargoAmount || 0
                    basketStep.value = 3
                    toplamOdenecekTutar.value = responseStep2.value.detail?.ordering?.totalAmount || 0
                    toplamOdenecekTutarHavale.value = responseStep2.value.detail?.ordering?.totalAmount || 0
                    loading.value = false

                    // toplamOdenecekTutar.value = responseStep2.value.detail?.ordering?.totalAmount || 0
                    // toplamOdenecekTutarHavale.value = responseStep2.value.detail?.ordering?.totalAmount || 0

                    store.dispatch('app-order/fetchMakeOrderStep3', payload).then(async response => {
                      if (response.error === 0) {
                        store.commit('app/TOGGLE_BASKET_DRAWER', false)
                        router.push({ name: 'order-completed' })
                        paymentErrorMsg.value = response.msg
                        paymentError.value = true
                      } else {
                        Vue.swal({
                          title: 'Hata',
                          html: response.msg,
                          icon: 'error',
                          background: '#FF4C51',
                          timerProgressBar: true,
                          showConfirmButton: false,
                        })
                      }
                    })
                  })
                }
              })
            })
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              html: result.msg,
              icon: 'error',

              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }
        })
      }
    }

    const orderStatus = ref()
    const paymentErrorMsg = ref(null)
    const paymentError = ref(false)
    let iframe

    const krediKartTemizle = ref(0)
    window.addEventListener('message', e => {
      if (e.data.success) {
        dialog.value = false
        iframe.remove()

        if (e.data.ismulti) {
          if (e.data.iscomplete) {
            store.commit('app/TOGGLE_BASKET_DRAWER', false)
            router.push({ name: 'order-completed' })
          } else {
            isMulti.value = true
            ikinciKart.value = e.data.multicardremainamount
            buttonText.value = 'İkinci Kart İle Devam'
            krediKartTemizle.value += 1
          }
        } else {
          store.commit('app/TOGGLE_BASKET_DRAWER', false)
          router.push({ name: 'order-completed' })
        }
      }
    })

    const namaSevkMusteri = ref()
    const namaSevkMusteriItems = ref([])

    const namaSevkAddressItems = ref([])

    const paymentCancel = () => {
      orderStatus.value = null
      paymentErrorMsg.value = null
      paymentError.value = false
      loading.value = false
      iframe.remove()
      whileStop.value = true
      orderStatus.value = null
      dialog.value = false
    }
    postData({
      method: 'getCustomersBySalDept',
      type: 'select',
    }).then(response => {
      namaSevkMusteriItems.value = response.detail
    })

    const desserts = ref([])

    const buttonDisabled = ref(false)
    watch(birinciKart, val => {
      const odeme = taksitTutar.value || toplamOdenecekTutar.value
      ikinciKart.value = (odeme - val).toFixed(2)
      if (ikinciKart.value < 0.1) {
        ikinciKart.value = 'Hata'
        buttonDisabled.value = true
      } else {
        buttonDisabled.value = false
      }
    })

    return {
      shallShadowBottom,
      teslimatTipsSelect1,
      hasMalFazlasi,
      hasPromotion,
      krediKartTemizle,
      buttonDisabled,
      buttonText,
      birinciKart,
      ikinciKart,
      paymentCancel,
      paymentErrorMsg,
      paymentError,
      desserts,

      namaSevkAddressItems,
      userData,
      namaSevkDurum,
      namaSevkMusteri,
      namaSevkMusteriItems,
      mesafeliStatus,
      onBilgilendirmeStatus,
      form,
      sepetLoading,
      dialog,
      statusFind,
      basketStep2,
      backBasket,
      cargoPrice,
      formValidate,

      // basketStepCompleted,
      farkliSehirSelect1,
      farkliAdress,
      farkliSehir,
      farkliIlce,
      farkliMusteriUnvani,
      isfarkliAddress,
      buyerNote,
      basketStep3,
      disableItem,
      counts,
      isRamazanEvent,
      sehirler,
      bankItem,
      tab,
      perfectScrollbarOptions,
      shoppingCartItems,
      addressItems,
      adressSehir,
      errorShow,
      deleteBasketItem,
      musteri,
      number,
      mesafeliSatisSozlesmesi,
      onBilgilendirmeSozlesmesi,
      fabrika,
      tarih,
      checkbox1,
      checkbox2,
      basketStep,
      closeBasket,
      refreshBasket,
      addressSelect,
      teslimatTipsSelect,
      toplamOdenecekTutar,
      toplamOdenecekTutarHavale,
      taksitlendirme,
      taksitSecim,
      deliveryDate,
      menuDeliveryDate,
      minDate,
      maxDate,
      adresSubcust,
      dateChange,
      loading,
      bankalar,
      valid,
      xx,
      trashBasket,
      cardNumberValidate,
      cardNameValidate,
      required,
      cardMountValidate,
      cardYearValidate,
      cardCvvValidate,
      kkAdSoyad,
      kkAy,
      isSubcust,
      kkYil,
      kkCvv,
      kkNo,
      addressSelect1,
      kredikartino,
      kredikartiadsoyad,
      dynamic,
      kredikartiay,
      kredikartiyil,
      kredikarticvv,
      kredikartiinstallment,
      selectedInstallment,
      bankaKodu,
      cardFamily,
      cardBankName,
      taksitTutar,
      teslimatTips,
      taksitSayisi,
      snackbar,
      scrollbar,

      tabHavaleDisabled,
      tabCHDisabled,
      tabCHnone,
      isMulti,
      icons: {
        mdiText,
        mdiChevronDown,
        mdiChevronUp,
        mdiClose,
        mdiTruckFast,
        mdiTrashCan,
        mdiContactlessPayment,
        mdiRefresh,

        mdiBasket,
        mdiCalendar,
        mdiPlus,
        mdiCreditCard,
        mdiCreditCardPlus,
        mdiBank,
        mdiMapMarker,
        mdiBasketOff,
        mdiCircle,
        mdiCloseCircleOutline,
      },
    }
  },
  data() {
    return {
      select: { state: 'Florida', abbr: 'FL' },
      items: [
        { state: 'Florida', abbr: 'FL' },
        { state: 'Georgia', abbr: 'GA' },
        { state: 'Nebraska', abbr: 'NE' },
        { state: 'California', abbr: 'CA' },
        { state: 'New York', abbr: 'NY' },
      ],
      headers: [
        {
          text: 'Ad',
          align: 'right',
          sortable: false,
          value: 'name',
        },

        {
          text: 'Değer',
          sortable: false,
          align: 'right',
          value: 'tutar',
        },
      ],
      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },
    }
  },
  computed: {
    satirToplamFiyat() {
      return this.shoppingCartItems?.toplamlar?.satirToplamFiyat
    },
    satirToplamKDV() {
      return this.shoppingCartItems?.toplamlar?.satirToplamKDV
    },
    toplamBrutFiyat() {
      return this.shoppingCartItems?.toplamlar?.toplamBrutFiyat
    },
    toplamFiyat() {
      return this.shoppingCartItems?.toplamlar?.toplamFiyat
    },
    toplamIndirimTutari() {
      return this.shoppingCartItems?.toplamlar?.toplamIndirimTutari
    },
    urunSayisi() {
      return this.shoppingCartItems?.toplamlar?.urunSayisi
    },

    address_title() {
      let result = null
      if (this.addressItems.length > 0) {
        result = this.addressItems[this.addressSelect]?.address_title
      }

      return result
    },
  },
  watch: {
    krediKartTemizle(val) {
      this.formData = {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      }
    },
    taksitSecim(val) {
      this.toplamOdenecekTutar = this.taksitlendirme.installment[val].inst_tot_amount

      this.taksitTutar = this.taksitlendirme.installment[val].inst_amount
      this.taksitSayisi = this.taksitlendirme.installment[val].installment_text.charAt(0)
    },
    tab(val) {
      // if (val === 0) {
      //   this.toplamOdenecekTutar = this.taksitlendirme?.installment[this.taksitSecim]?.inst_tot_amount
      // } else {
      //   this.toplamOdenecekTutar = 0
      // }
    },
  },
  methods: {
    updateCardNumber(val) {
      if (val.length > 18) {
        const payload = {
          method: 'getInstallmentsByBin',
          kk_no: val.replaceAll(' ', ''), // 4506347048543223
          price: this.toplamOdenecekTutar,
        }
        this.$store.dispatch('app-order/fetchBinList', payload).then(response => {
          this.taksitlendirme = response

          this.cardBankName = response.card_bank_name
          this.cardFamily = response.card_family
          this.bankaKodu = response.card_bank_name
          this.kredikartiinstallment = response.installment
        })
        this.cardNumberValidate = true
        this.kkNo = val
        this.kredikartino = val
      } else {
        this.taksitlendirme = {}
        this.cardNumberValidate = false
      }
    },
    updateCardName(val) {
      if (val) {
        this.cardNameValidate = true
        this.kkAdSoyad = val
        this.kredikartiadsoyad = val
      } else {
        this.cardNameValidate = false
      }
    },
    updateCardMonth(val) {
      if (val) {
        this.cardMountValidate = true
        this.kkAy = val
        this.kredikartiay = val
      } else {
        this.cardMountValidate = false
      }
    },
    updateCardYear(val) {
      if (val) {
        this.cardYearValidate = true
        this.kkYil = val
        this.kredikartiyil = val
      } else {
        this.cardYearValidate = false
      }
    },
    updateCardCvv(val) {
      if (val.length >= 3) {
        this.cardCvvValidate = true
        this.kkCvv = val
        this.kredikarticvv = val
      } else {
        this.cardCvvValidate = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/style.scss';
// Set Perfect Scrollbar Container Height
.vertical-shopping-cart-container {
  height: 100%;
  .ps-nav-menu-items {
    height: calc(100% - 35px) !important;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      height: calc(100%) !important;
      background-color: transparent !important;
    }
  }
}
.my-alert .v-alert__content {
  color: rgb(155, 122, 72) !important;
}
.v-stepper__step__step {
  display: none !important;
}
.v-application--is-ltr .theme--dark.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 0 !important;
}
.v-application--is-ltr .v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 0px !important;
}
.hre {
  opacity: 1;
  margin: 0;
  color: black;
}
.ps {
  -ms-overflow-style: none !important; /* IE 10+ */
  scrollbar-width: thin; /* Firefox */
}
.ps-nav-menu-items {
  background-color: transparent !important;
}
</style>
