// eslint-disable-next-line object-curly-newline
import {
  mdiAccountDetailsOutline,
  mdiAccountGroup,
  mdiAccountTie,
  mdiCart,
  mdiCartArrowRight,
  mdiCartCheck,
  mdiCog,
  mdiCollage,
  mdiCreditCard,
  mdiFileDocumentOutline,
  mdiFileExport,
  mdiFileMultipleOutline,
  mdiFilePlusOutline,
  mdiFileSearch,
  mdiNewspaperPlus,
  mdiNote,
  mdiNoteText,
  mdiOrderBoolAscending,
  mdiPoll,
  mdiTruckDelivery,
  mdiViewDashboard,
  mdiViewList,
  mdiWallet,
} from '@mdi/js'

const accessToken = localStorage.getItem('accessToken')
const userData = JSON.parse(localStorage.getItem('userData'))

let dynamic = userData?.role === 'SUBCUST' ? 'Sipariş Takip' : 'Tali Bayi Sipariş Liste / Onay'

export default [
  {
    title: 'Anasayfa',
    icon: mdiViewDashboard,
    to: 'dashboard',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    subheader: 'SATIŞ',
    resource: 'Order',
    action: 'read',
  },

  {
    title: 'Satış Analizi',
    icon: mdiPoll,
    to: 'satisAnaliz',
    resource: 'SalesPoints',
    action: 'read',
  },
  {
    title: 'Sipariş Girişi',
    icon: mdiCart,
    to: 'order-easy',
    resource: 'Order',
    action: 'read',
  },
  {
    title: 'Aktarılmamış Sipariş',
    icon: mdiCartArrowRight,
    to: 'order-tracking',
    resource: 'SalesPoints',
    action: 'read',
  },
  {
    title: 'Açık Siparişler',
    icon: mdiOrderBoolAscending,
    to: 'open-orders',
    resource: 'SalesPoints',
    action: 'read',
  },

  {
    title: dynamic,
    icon: mdiCartCheck,
    to: 'tali-bayi',
    resource: 'TaliBayi',
    action: 'read',
  },
  {
    title: 'Sipariş Listesi',
    icon: mdiViewList,
    to: { name: 'documents-list', params: { folder: 'order' } },
    resource: 'OrderList',
    action: 'read',
  },
  {
    title: 'İrsaliye Listesi',
    icon: mdiNote,
    to: { name: 'documents-list', params: { folder: 'waybill' } },
    resource: 'OrderList',
    action: 'read',
  },
  {
    title: 'Fatura Listesi',
    icon: mdiNoteText,
    to: { name: 'documents-list', params: { folder: 'bill' } },
    resource: 'OrderList',
    action: 'read',
  },

  {
    title: 'Kargo Takip',
    icon: mdiTruckDelivery,
    to: '',
    resource: 'SalesPoints',
    action: 'read',
  },

  {
    subheader: 'FİNANS VE MUHASEBE',
    resource: 'ProductsMan',
    action: 'read',
  },

  {
    title: 'Cari Hesap Ekstresi',
    icon: mdiAccountDetailsOutline,
    to: 'extract-list',
    resource: 'SalesPoints',
    action: 'read',
  },
  {
    title: 'Cari Hesap Bakiyeleri',
    icon: mdiWallet,
    to: 'cari-hesap',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Online Ödeme',
    icon: mdiCreditCard,
    //to: 'payment',
    href: `https://odeme.anlas.com`,
    resource: 'ProductsMan',
    action: 'read',
  },

  {
    subheader: 'SATIŞ SONRASI HİZMETLER',
    resource: 'GeneralSettings',
    action: 'read',
  },

  {
    title: 'Talep Giriş',
    icon: mdiFileExport,
    to: '',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Talep Takip',
    icon: mdiFileSearch,
    to: '',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    subheader: 'BELGELER',
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: 'Belgeler',
    icon: mdiFileMultipleOutline,
    to: 'belgeler',
    resource: 'KPanel',
    action: 'read',
  },

  {
    subheader: 'YÖNETİM SİSTEMİ',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Kullanıcılar',
    icon: mdiAccountGroup,
    to: 'user-list',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Yöneticiler',
    icon: mdiAccountTie,
    to: 'admin-list',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Ayarlar',
    icon: mdiCog,
    to: 'settings-view',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Ürünler',
    icon: mdiCollage,
    to: 'product-list',
    resource: 'GeneralSettings',
    action: 'read',
  },

  {
    title: 'Belge Yükleme',
    icon: mdiFilePlusOutline,
    to: 'belge-list',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Haber-Duyuru Yükleme',
    icon: mdiNewspaperPlus,
    to: 'event-list',
    resource: 'GeneralSettings',
    action: 'read',
  },

  {
    title: 'Integrations',
    href: `https://onlineapi.anlas.com/tr/integrations?token=${accessToken}`,
    icon: mdiFileDocumentOutline,
    resource: 'GeneralSettings',
    action: 'read',
  },
]

/*
  {
    subheader: 'ANASAYFA',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Ana Sayfa',
    icon: mdiHomeOutline,
    to: 'dashboard',
    resource: 'Dashboard',
    action: 'read',
  },

  {
    subheader: 'Hesaplar',
    resource: 'Users',
    action: 'read',
  },

  {
    title: 'Kullanıcılar',
    icon: mdiAccountSupervisor,
    to: 'user-list',
    resource: 'Users',
    action: 'read',
  },
 
  {
    title: 'Fiyat Listeleri',
    icon: mdiCashMultiple,
    to: '',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Banka Hesapları',
    icon: mdiBank,
    to: '',
    resource: 'KPanel',
    action: 'read',
  },
   {
    subheader: 'Ürün & Takip',
    resource: 'Users',
    action: 'read',
  },
 
   {
    title: 'Kolay Sipariş Ver',
    icon: mdiFlash,
    to: 'order-easy',
    resource: 'Products',
    action: 'read',
  },
   {
    subheader: 'Ödeme',
    resource: 'KPanel',
    action: 'read',
  },
  {
    title: 'Kart Hareketleri',
    icon: mdiCreditCard,
    to: 'credit-card-transactions',
    resource: 'PaymentActions',
    action: 'read',
  },
  
  {
    subheader: 'Hesap',
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: 'Kullanıcı Bilgilerim',
    icon: mdiShieldAccount,
    to: { name: 'user-view' },
    resource: 'KPanel',
    action: 'read',
  },
  {
    title: 'Adreslerim',
    icon: mdiMapMarker,
    to: { name: 'user-addresses' },
    resource: 'KPanel',
    action: 'read',
  },

    {
    title: 'Haber Yükleme',
    icon: mdiNewspaperPlus,
    to: '',
    resource: 'Events',
    action: 'read',
  },
  {
    title: 'Sözleşmelerim',
    icon: mdiFileLock,
    to: 'user-contracts',
    resource: 'KPanel',
    action: 'read',
  },
  {
    subheader: 'Sistem',
    resource: 'Users',
    action: 'read',
  },
  
  {
    subheader: 'Raporlama',
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: 'Satış Belgeleri',
    icon: mdiFileDocument,
    resource: 'KPanel',
    action: 'read',
    children: [
      {
        title: 'Sipariş',
        to: { name: 'documents-list', params: { folder: 'order' } },
        resource: 'KPanel',
        action: 'read',
        titleColor: 'blue',
      },
      {
        title: 'İrsaliye',
        to: { name: 'documents-list', params: { folder: 'waybill' } },
        resource: 'KPanel',
        action: 'read',
      },
      {
        title: 'Fatura',
        to: { name: 'documents-list', params: { folder: 'bill' } },
        resource: 'KPanel',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ekstre',
    icon: mdiTrayFull,
    to: 'extract-list',
    resource: 'KPanel',
    action: 'read',
  },
  {
    title: 'Toplu SMS',
    icon: mdiMessageProcessing,
    to: 'sms-list',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Loglar',
    icon: mdiCrosshairs,
    to: 'logs',
    resource: 'Logs',
    action: 'read',
  },


  */
